._1Xr9x {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: auto;
  padding: 6px;
  border: 1px solid #E9EFF4;
  border-radius: 4px; }
  ._1Xr9x ._1hDjE {
    box-sizing: border-box;
    background-color: #E9EFF4;
    border-radius: 20px;
    display: inline-flex;
    align-items: flex-start;
    justify-content: flex-start;
    margin: 4px;
    padding: 6px 10px 6px 12px;
    font-size: 12px;
    line-height: 16px; }
    ._1Xr9x ._1hDjE._23Kl0 {
      -webkit-animation: _1k9WG 1.5s 1;
              animation: _1k9WG 1.5s 1;
      transition: all 0.8s ease-out;
      background-color: red !important;
      color: #fff !important;
      position: relative; }
      ._1Xr9x ._1hDjE._23Kl0 ._iP5XP {
        color: #fff !important; }
    ._1Xr9x ._1hDjE._26NUl {
      -webkit-animation: _1k9WG 1.5s 1;
              animation: _1k9WG 1.5s 1;
      transition: all 0.8s ease-out;
      background-color: #fff !important;
      border: 1px solid red;
      color: red !important;
      position: relative; }
      ._1Xr9x ._1hDjE._26NUl ._iP5XP {
        color: red !important; }
    ._1Xr9x ._1hDjE ._iP5XP {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
      border: none !important;
      background-color: transparent;
      letter-spacing: 0.05em;
      font-size: 100%;
      color: #444444; }
      ._1Xr9x ._1hDjE ._iP5XP:hover, ._1Xr9x ._1hDjE ._iP5XP:focus, ._1Xr9x ._1hDjE ._iP5XP:focus-visible {
        outline: none;
        box-shadow: none; }
    ._1Xr9x ._1hDjE ._1tTm- {
      box-sizing: border-box; }
      ._1Xr9x ._1hDjE ._1tTm-._3dU1T {
        padding: 0;
        margin: 0;
        border: none;
        background-color: transparent; }
        ._1Xr9x ._1hDjE ._1tTm-._3dU1T:hover, ._1Xr9x ._1hDjE ._1tTm-._3dU1T:focus, ._1Xr9x ._1hDjE ._1tTm-._3dU1T:active, ._1Xr9x ._1hDjE ._1tTm-._3dU1T:focus-visible {
          outline: none;
          box-shadow: none; }
      ._1Xr9x ._1hDjE ._1tTm-._IV-Yz {
        border: none;
        border-radius: 30px;
        flex: 0 0 16px;
        width: 16px;
        height: 16px;
        margin: 0 0 0 10px;
        padding: 0;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        font-size: 85%;
        color: #000;
        font-family: "Verdana", "Arial", Helvetica, sans-serif; }
        ._1Xr9x ._1hDjE ._1tTm-._IV-Yz ._usjXk {
          width: 10px;
          height: 10px;
          transform: scale(0.8);
          color: currentColor; }
          ._1Xr9x ._1hDjE ._1tTm-._IV-Yz ._usjXk path {
            stroke: currentColor; }
        ._1Xr9x ._1hDjE ._1tTm-._IV-Yz span {
          color: #444444; }

._1tTm-._3dU1T {
  box-sizing: border-box; }
  ._1tTm-._3dU1T._1YeWk {
    padding: 5px 8px;
    margin: 4px;
    border: none;
    background-color: transparent;
    font-weight: 500;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0.05em;
    color: #4DA1FF; }
    ._1tTm-._3dU1T._1YeWk:hover {
      outline: none;
      box-shadow: none;
      background-color: transparent; }

@-webkit-keyframes _1k9WG {
  0% {
    top: 0;
    left: 0;
    opacity: 1; }
  60% {
    top: 0;
    left: 0px;
    opacity: 1; }
  100% {
    top: 0;
    left: -8px;
    opacity: 0; } }

@keyframes _1k9WG {
  0% {
    top: 0;
    left: 0;
    opacity: 1; }
  60% {
    top: 0;
    left: 0px;
    opacity: 1; }
  100% {
    top: 0;
    left: -8px;
    opacity: 0; } }
